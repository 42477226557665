import React, { useContext, useState, useEffect, createContext } from "react";
import axios from "axios";

import socketIOClient from "socket.io-client";
const ENDPOINT = "http://hud.dingues.tv:4001";
const socket = socketIOClient(ENDPOINT);

const moment = require("moment");
const APIContext = createContext();

var _ = require("lodash");

export function SocketContextProvider(props) {
  const [scoreboard, setScoreboard] = useState(null);
  const [meme, setMeme] = useState({ action: "play", id: "clear", date: 1 });

  useEffect(() => {
    socket.on("scoreboard", (data) => {
      setScoreboard(data);
    });

    socket.on("meme", (data) => {
      setMeme(data);
    });
  }, [props, meme, scoreboard]);

  const onEnded = (event) => {
    setMeme({ action: "play", id: "clear", date: 1 });
  };

  return (
    <APIContext.Provider
      value={{
        meme,
        scoreboard,
        onEnded,
      }}
    >
      {props.children}
    </APIContext.Provider>
  );
}

let count = 0;

export function APIContextProvider(props) {
  const [status, setStatus] = useState("init");
  const [data, setData] = useState(null);
  const [scoreboard, setScoreboard] = useState(null);
  const [notif, setNotif] = useState(null);
  const [notifID, setNotifID] = useState(0);
  const [matchTime, setMatchTime] = useState("00:00");

  useEffect(() => {
    const infoTimer = setInterval(() => {
      if (count === 10 || status === "init" || status === "error") {
        setStatus("loading");
        console.log("loading");
        axios
          .get("http://ffroller.dingues.tv/match/" + props.matchID)
          .then(function (response) {
            setData(response.data);
            
            if (response.data.scoreboard.teamA) {
              setScoreboard(response.data.scoreboard);

              count = 0;

              let actions = response.data.actions;
              if (actions.length > 0) {
                if (actions[0].id != notifID) {
                  setNotif(actions[0]);
                  setNotifID(actions[0].id);
                  console.log(actions[0]);
                }
              }
              setStatus("stop");
              console.log("stop");
            } else {
              console.log("error : scoreboard null");
              setStatus("error");
            }
          })
          .catch(function (error) {
            setStatus("error");
            console.log(error);
          });
      }

      //clear notif
      if (count === 6) {
        console.log("clear notif");
        setNotif(null);
      }

      if (scoreboard) {
        var timestring = "2013-05-09T00:" + scoreboard.time + "Z";
        var startdate = moment(timestring);
        count++;
        startdate.add(count, "s");
        setMatchTime(startdate.format("mm:ss"));
      }
    }, 1000);

    return () => {
      clearInterval(infoTimer);
    };
  }, [props, data, status, notifID, matchTime, notif, scoreboard]);

  return (
    <APIContext.Provider
      value={{
        data,
        notif,
        matchTime,
        scoreboard,
      }}
    >
      {props.children}
    </APIContext.Provider>
  );
}

function createNotif(data) {
  console.log(data.evenements);
  let actions = [];

  data.evenements.forEach( (elt) => {
    if (elt.type = "BUT" &&  elt.buteur) {
      let action = {};
      action.time =  elt.temps;
      action.type = "goal";
      action.id = action.time;
      
      action.notif = {};
      action.notif.title = "BUT !";
      //console.log(elt);
      action.notif.msg = elt.buteur.nom_complet;
      console.log(action);
      actions.unshift(action);
    }
  }); 

  return actions;
 /* nom_complet
:
"M MOLINA Gauthier"
numero
:
"78"
*/
  /*        "time": "04:22",
            "type": "goal",
            "player": {
                "name": "M ROUMET Raphael",
                "number": "N˚76"
            },
            "assist": {
                "name": "M RODZIEWICZ Timeo",
                "number": "N˚7"
            },
            "notif": {
                "title": "But",
                "msg": "04:22 - M ROUMET Raphael"
            },
            "id": "d0b9efe9fd34360a1107f7c57f1ad3a2"*/

}

function createScoreBoard(data) {
  let scoreboard;

  let teamAid = data.receveur.id;
  let teamBid = data.visiteur.id;

  let scoreA =
    data.scores[
      _.findIndex(data.scores, function (o) {
        return o.equipe_id == teamAid;
      })
    ];
  let scoreB =
    data.scores[
      _.findIndex(data.scores, function (o) {
        return o.equipe_id == teamBid;
      })
    ];

  let status = data.etat;
  if (!data.etat) status = "wait";
  else if (data.etat === "T") status = "ended";
  else status = "running";

  console.log(status);
  return {
    id: 1,
    teamA: data.receveur.libelle_court,
    teamB: data.visiteur.libelle_court,
    scoreA: scoreA.score,
    scoreB: scoreB.score,
    state: status,
    time: "00:00",
    round: "P1",
    theme: "hockey",
    visibility: "visible",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  };
}

export function API2ContextProvider(props) {
  const [status, setStatus] = useState("init");
  const [data, setData] = useState(null);
  const [scoreboard, setScoreboard] = useState(null);
  const [notif, setNotif] = useState(null);
  const [notifID, setNotifID] = useState(0);
  const [matchTime, setMatchTime] = useState("00:00");

  useEffect(() => {
    const infoTimer = setInterval(() => {
      if (count === 10 || status === "init") {
        setStatus("loading");
        console.log("loading");
        axios
          .get(
            "http://ffroller.dingues.tv/match/" +
              props.matchID +
              "/" +
              props.token
            //"92Una3QbXY3UUZnx4KaTH7WqX2XuGL5qWkSPqOKM"
          )
          .then(function (response) {
            console.log("stop");
            console.log(response.data);
            count = 0;
            setScoreboard(createScoreBoard(response.data));

            let actions = createNotif(response.data);

            if (actions.length > 0) {
              if (actions[0].id != notifID) {
                setNotif(actions[0]);
                setNotifID(actions[0].id);
                console.log(actions[0]);
              }
            }
          })
          .catch(function (error) {
            count = 0;
            console.log("stop");
            console.log(error);
          });
      }

      if (scoreboard && scoreboard.state != "ended") {
        var timestring = "2013-05-09T00:" + scoreboard.time + "Z";
        var startdate = moment(timestring);
        count++;
        startdate.add(count, "s");
        setMatchTime(startdate.format("mm:ss"));
      }
    }, 1000);

    return () => {
      clearInterval(infoTimer);
    };
  }, [props, data, status, notifID, matchTime, notif, scoreboard]);

  return (
    <APIContext.Provider
      value={{
        data,
        notif,
        matchTime,
        scoreboard,
      }}
    >
      {props.children}
    </APIContext.Provider>
  );
}

export function useAPI() {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
