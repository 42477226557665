import React, { useContext, useState, useEffect, createContext } from "react";
import "./scoreboard2.css";
import { useAPI } from "./APIContext";

export default function HockeyScoreboard(props) {
  const { scoreboard, matchTime } = useAPI();

  if (!scoreboard) return <div></div>;

  return (
    <div className="scaled">
      <div
        className={
          "scoreboard-hockey is-" +
          scoreboard.visibility +
          " " +
          scoreboard.theme +
          "-bg"
        }
      >
        <div className="hockey-col hockey-teama">
          <span className={"team-name team-name-" + scoreboard.theme}>
            {scoreboard.teamA}
          </span>
          <span className={"hockey-score-team score-team-" + scoreboard.theme}>
            {scoreboard.scoreA}
          </span>
        </div>
        <div className="hockey-col hockey-teamb">
          <span className={"team-name team-name-" + scoreboard.theme}>
            {scoreboard.teamB}
          </span>
          <span className={"hockey-score-team score-team-" + scoreboard.theme}>
            {scoreboard.scoreB}
          </span>
        </div>
      </div>
      {scoreboard.state === "Running" && (
        <div className="hockey-round">
          <span className={"round-" + scoreboard.theme}>
            {scoreboard.round} - {matchTime}
          </span>
        </div>
      )}
    </div>
  );
}
