import React from "react"

import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

export default function BottomNav(props) {
  const [value, setValue] = React.useState(0);

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          props.onNavChange(newValue);
        }}
      >
        <BottomNavigationAction label="Scoreboard" icon={<SettingsApplicationsIcon />} />
        <BottomNavigationAction label="Meme" icon={<OndemandVideoIcon />} />
      </BottomNavigation>
    </Paper>
  );
}
