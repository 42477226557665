import React  from "react";
import { useAPI } from "./APIContext";

export default function MemePlayer(props) {
  const { meme, onEnded } = useAPI();
  /*const onEnded = (event) => {
    setMeme({ action: "play", id: "clear", date: 1 });
  };*/

    return (
    <div>
      <video
        width="100%"
        autoPlay
        preload="auto"
        onEnded={onEnded}
        key={meme.date}
      >
        <source src={"/greenscreen/" + meme.id} type="video/mp4"></source>
      </video>
    </div>
  );
}
