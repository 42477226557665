import React from "react";
import { useAPI } from "../components/APIContext";

import MemePlayer from "../components/memePlayer";
import BBScoreboard from "../components/bbscoreboard";

import {
  SocketContextProvider,
} from "../components/APIContext";

function HudBloodBowl() {
  return (
    <div>
      <SocketContextProvider>
        <BBScoreboard/>
        <MemePlayer/>
      </SocketContextProvider>
    </div>
  );
}

export default HudBloodBowl;
