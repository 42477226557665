import React from "react";
import "./scoreboard.css";
import { useAPI } from "./APIContext";

export default function ScoreboardPreview(props) {
  const { scoreboard } = useAPI();

  if (!scoreboard) return <div>No scoreboard</div>;

  const deadCount = (numrows) => {
    if (numrows <= 0) return <ul className="skull-list-hidden" />;
    let list = [];
    for (var i = 0; i < numrows; i++) {
      list.push(
        <li key={"skull" + i} className="skull">
          <img width="24px" alt="skull" src="/skull.png" />
        </li>
      );
    }
    return (
      <ul className={"skull-list skull-list-" + scoreboard.theme}>{list}</ul>
    );
  };

  return (
    <div
      className={
        "scoreboard is-visible " + 
        scoreboard.theme +
        "-bg"
      }
    >
      <div className="col col-team-name">
        <span className={"team-name team-name-" + scoreboard.theme}>
          {scoreboard.teamA}
        </span>
        <span className={"team-name team-name-" + scoreboard.theme}>
          {scoreboard.teamB}
        </span>
      </div>

      <div className="col">
        <span className={"score-team score-team-" + scoreboard.theme}>
          {scoreboard.scoreA}
        </span>
        <span className={"score-team score-team-" + scoreboard.theme}>
          {scoreboard.scoreB}
        </span>
      </div>

      <div className="col">
        {deadCount(scoreboard.deadA)}
        <span className={"round round-" + scoreboard.theme}>
          Round {scoreboard.round}
        </span>
        {deadCount(scoreboard.deadB)}
      </div>
    </div>
  );
}
