import React from "react";
import {
  Routes,
  Route,
} from "react-router-dom";

import Hud from "./views/hud";
import Preview from "./views/preview";
import Admin from "./views/admin";
import Hockey from "./views/hockey";
import Hockeyv2 from "./views/hockeyv2";
import HudBloodbowl from "./views/hudbloodbowl";


function App() {
  return (
    <Routes>
      <Route index element={<Hud />} />
      <Route path="hud" element={<Hud />} />
      <Route path="preview" element={<Preview />} />
      <Route path="admin" element={<Admin />} />
      <Route path="bloodbowl" element={<HudBloodbowl />} />
      <Route path="hockey/:id" element={<Hockey />} />
      <Route path="hockey/:id/:token" element={<Hockeyv2 />} />
    </Routes>
  );
}

export default App;
