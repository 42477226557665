import React from "react";

import { useParams } from "react-router-dom";

import { useAPI } from "../components/APIContext";

import MemePlayer from "../components/memePlayer";
import HockeyScoreboard from "../components/hockeyScoreboard";
import Banner from "../components/banner";

//"23521"
import {
  SocketContextProvider,
  APIContextProvider,
} from "../components/APIContext";

function Hockey() {
  let { id } = useParams();
  return (
    <div>
      <SocketContextProvider>
        <MemePlayer />
      </SocketContextProvider>

      <APIContextProvider matchID={id} duration="10000">
        <HockeyScoreboard />
        <Banner />
      </APIContextProvider>
    </div>
  );
}

export default Hockey;
