import React from "react";
import "./banner.css";
import { useAPI } from "./APIContext";

export default function Banner(props) {
  const { notif } = useAPI();

  if (notif && notif.notif)
    return (
      <div className="breaking_news breaking_news_show">
        <div className="label ripple">{notif.notif.title}</div>
        {notif.notif.msg && (
          <div className="news_title">{notif.notif.msg}</div>
        )}
      </div>
    );
  return <div />;
}
