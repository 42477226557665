import React from "react";
import Arbo from './arbo.json';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function memes(callback) {
  let result = [];
  let folders = Arbo[0].contents;
 
  folders.forEach(folder => {
    let namesList = folder.contents.map(function(file) {
      let path = folder.name + "/" + file.name;
      return <Chip sx={{ m: 1 }} size="small" 
          variant="outlined" 
          key={file.name}
          id={file.name} 
          onClick={ () => callback(path)} label={file.name} 
        />
    })
    
  result.push(
      <Accordion key={folder.name}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
        {folder.name}
        </AccordionSummary>
        <AccordionDetails>
          {namesList}
        </AccordionDetails>
      </Accordion>)
  });

  return result;
}

export default function memePanel(props) {
  return (
    <Box sx={{ bgcolor: '#cfe8fc', height: '100vh' }} >
        {memes(props.onClick)}
      <Button style={{ margin:10}} variant="contained" id="clear" onClick={ () => props.onClick("clear")}>CLEAR</Button>
    </Box>
  );
}
