import React, { useState, useEffect } from "react";
import socketIOClient from "socket.io-client";
import BottomNav from "../components/bottomNav";
import MemePanel from "../components/memePanel";
import SelectGameDialog from "../components/selectGame";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import AddVisibility from "@mui/icons-material/Visibility";
import RemoveIcon from "@mui/icons-material/Remove";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

import "./admin.css";

const ENDPOINT = "http://hud.dingues.tv:4001";
const socket = socketIOClient(ENDPOINT);

function Admin() {
  const [data, setData] = useState({});
  const [teamA, setTeamA] = useState("???");
  const [teamB, setTeamB] = useState("???");
  const [currentNav, setCurrentNav] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("necromunda");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
    console.log(value);
    socket.emit("scoreboard", { action: "set_theme", theme: value });
  };

  const handleChangeTeamB = (event) => {
    setTeamB(event.target.value);
  };

  const handleChangeTeamA = (event) => {
    setTeamA(event.target.value);
  };

  const onNavChange = (value) => {
    setCurrentNav(value);
  };

  const changeNames = () => {
    socket.emit("scoreboard", {
      action: "change_name",
      teamA: teamA,
      teamB: teamB,
    });
  };

  const handleClickPlay = (meme) => {
    socket.emit("meme", { action: "play", id: meme });
  };

  const handleClick = (action) => {
    console.log(action);
    socket.emit("scoreboard", { action: action });
  };

  useEffect(() => {
    socket.on("scoreboard", (data) => {
      setData(data);
      setTeamA(data.teamA);
      setTeamB(data.teamB);
    });
  }, []);

  return (
    <div>
      {currentNav === 0 && (
        <Paper sx={{ width: "100%" }} elevation={3}>
          <div className="mainPanel">
            <div className="panel">
              <Fab
                color="secondary"
                onClick={() => handleClick("toggle_visibility")}
                aria-label="add"
              >
                <AddVisibility />
              </Fab>
              <div>
                <Button variant="outlined" onClick={handleClickOpen}>
                  Select a game
                </Button>
                <SelectGameDialog
                  selectedValue={selectedValue}
                  open={open}
                  onClose={handleClose}
                />
              </div>
            </div>

            <div className="panel">
              <Typography variant="caption">{teamA + " (score)"}</Typography>
              <ButtonGroup>
                <Button
                  aria-label="reduce"
                  onClick={() => handleClick("dec_score_A")}
                >
                  <RemoveIcon fontSize="small" />
                </Button>

                <Button
                  aria-label="increase"
                  onClick={() => handleClick("inc_score_A")}
                >
                  <AddIcon fontSize="small" />
                </Button>
              </ButtonGroup>
            </div>

            <div className="panel">
              <Typography variant="caption">{teamA + " (dead)"}</Typography>
              <ButtonGroup>
                <Button
                  aria-label="reduce"
                  onClick={() => handleClick("dec_dead_A")}
                >
                  <RemoveIcon fontSize="small" />
                </Button>

                <Button
                  aria-label="increase"
                  onClick={() => handleClick("inc_dead_A")}
                >
                  <AddIcon fontSize="small" />
                </Button>
              </ButtonGroup>
            </div>

            <Divider />

            <div className="panel">
              <Typography variant="caption">{teamB + " (score)"}</Typography>
              <ButtonGroup>
                <Button
                  aria-label="reduce"
                  onClick={() => handleClick("dec_score_B")}
                >
                  <RemoveIcon fontSize="small" />
                </Button>

                <Button
                  aria-label="increase"
                  onClick={() => handleClick("inc_score_B")}
                >
                  <AddIcon fontSize="small" />
                </Button>
              </ButtonGroup>
            </div>

            <div className="panel">
              <Typography variant="caption">{teamB + " (dead)"}</Typography>
              <ButtonGroup>
                <Button
                  aria-label="reduce"
                  onClick={() => handleClick("dec_dead_B")}
                >
                  <RemoveIcon fontSize="small" />
                </Button>

                <Button
                  aria-label="increase"
                  onClick={() => handleClick("inc_dead_B")}
                >
                  <AddIcon fontSize="small" />
                </Button>
              </ButtonGroup>
            </div>

            <Divider />

            <div className="panel">
              <Typography variant="caption">Rounds</Typography>
              <ButtonGroup>
                <Button
                  aria-label="reduce"
                  onClick={() => handleClick("dec_round")}
                >
                  <RemoveIcon fontSize="small" />
                </Button>

                <Button
                  aria-label="increase"
                  onClick={() => handleClick("inc_round")}
                >
                  <AddIcon fontSize="small" />
                </Button>
              </ButtonGroup>
            </div>

            <Divider />

            <div className="panel">
              <div className="coll">
                <TextField
                  id="standard-basic"
                  label="TeamA"
                  value={teamA}
                  onChange={handleChangeTeamA}
                  variant="standard"
                />
                <TextField
                  id="standard-basic"
                  label="TeamB"
                  value={teamB}
                  onChange={handleChangeTeamB}
                  variant="standard"
                />
              </div>
              <Button onClick={changeNames} variant="contained">
                Change
              </Button>
            </div>
          </div>
        </Paper>
      )}
      {currentNav === 1 && <MemePanel onClick={handleClickPlay} />}
      <BottomNav onNavChange={onNavChange} />
    </div>
  );
}

export default Admin;
