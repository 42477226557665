import React from "react";
import "./scoreboardbb.css";
import { useAPI } from "./APIContext";

export default function Scoreboardbb(props) {
  const { scoreboard } = useAPI();

  if (!scoreboard) return <div></div>;

  const deadCount = (numrows) => {
    if (numrows <= 0) return <ul className="skull-list-hidden" />;
    let list = [];
    for (var i = 0; i < numrows; i++) {
      list.push(
        <li key={"skull" + i} className="skull">
          <img width="24px" alt="skull" src="/skull.png" />
        </li>
      );
    }
    return <ul className={"skull-list skull-list-bloodbowl2"}>{list}</ul>;
  };

  return (
    <div
      className={
        "scoreboardbb is-" + scoreboard.visibility + " " + "bloodbowl2-bg"
      }
    >
      <div className={"scoreA"}>{scoreboard.scoreA}</div>
      <span className={"scoreB"}>{scoreboard.scoreB}</span>
      <div className={"deadA"}>{deadCount(scoreboard.deadA)}</div>
      <div className={"deadB"}>{deadCount(scoreboard.deadB)}</div>
      <div className={"round round-bloodbowl2"}>Round {scoreboard.round}</div>
      <div className={"logobim"}></div>
    </div>
  );
}
