import React from "react";
import { useAPI } from "../components/APIContext";
import ScoreboardPreview from "../components/scoreboardPreview";
import {
  SocketContextProvider,
} from "../components/APIContext";

function Preview() {
  return (
    <div>
      <SocketContextProvider>
        <ScoreboardPreview/>
      </SocketContextProvider>
    </div>
  );
}

export default Preview;
