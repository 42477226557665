import * as React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

const games = ['gameofthrones', 'necromunda', 'dust', 'starwarslegion', "wildwestexodus", "bloodbowl", "masterofunivers","bloodbowl2"];

SelectGameDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

function SelectGameDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Choose your game</DialogTitle>
      <List sx={{ pt: 0 }}>
        {games.map((game) => (
          <ListItem button onClick={() => handleListItemClick(game)} key={game}>
            <ListItemText primary={game} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default SelectGameDialog;
