import React from "react";

import { useParams } from "react-router-dom";

import { useAPI } from "../components/APIContext";

import MemePlayer from "../components/memePlayer";
import HockeyScoreboard from "../components/hockeyScoreboard";
import Banner from "../components/banner";

//"23521"
import {
  SocketContextProvider,
  API2ContextProvider,
} from "../components/APIContext";

function Hockeyv2() {
  let { id, token } = useParams();
  return (
    <div>
      <SocketContextProvider>
        <MemePlayer />
      </SocketContextProvider>

      <API2ContextProvider matchID={id} token={token} duration="10000">
        <HockeyScoreboard />
        <Banner />
      </API2ContextProvider>
    </div>
  );
}

export default Hockeyv2;
